import { extendTheme } from '@chakra-ui/react';
import { mode } from '@chakra-ui/theme-tools';

const theme = {
  styles: {
    global: props => ({
      body: {
        bg: mode('#1E1F29', '#1E1F29')(props),
        color: mode('#eceeff', '#eceeff')(props),
      },
    }),
  },
  colors: {
    bodyBackground: '#1E1F29',
    contentBackground: '#fff',
    headerBackground: '#031926',
    body: '#eceeff',
    bodyDark: '#15161e',
    dark: '#031926',
    light: '#F4E9CD',
    text: {
      muted: 'gray.700',
    },
    dracula: {
      background: '#282a36',
      currentLine: '#44475a',
      foreground: '#f8f8f2',
      comment: '#6272a4',
      cyan: {
        50: '#D7FFFB',
        100: '#C7FFFD',
        200: '#B7FFFF',
        300: '#A8FCFF',
        400: '#99F4FF',
        500: '#8be9fd',
        600: '#68D1D5',
        700: '#318181',
        800: '#318181',
        900: '#1C5757',
      },
      green: {
        50: '#C7FFC3',
        100: '#ABFFAB',
        200: '#93FF9A',
        300: '#7CFF8C',
        400: '#66FD82',
        500: '#50fa7b',
        600: '#31D554',
        700: '#19AB30',
        800: '#088114',
        900: '#005701',
      },
      orange: {
        50: '#FFF3CA',
        100: '#FFEBB6',
        200: '#FFE1A2',
        300: '#FFD590',
        400: '#FFC77E',
        500: '#ffb86c',
        600: '#ECA253',
        700: '#D98B3D',
        800: '#C67229',
        900: '#B35918',
      },
      pink: {
        50: '#E8CFFF',
        100: '#EDBCFF',
        200: '#F7ABFF',
        300: '#FF9AFD',
        400: '#FF89E4',
        500: '#ff79c6',
        600: '#EC60D1',
        700: '#D94AD9',
        800: '#C637C6',
        900: '#B326B3',
      },
      purple: {
        50: '#DEDAFF',
        100: '#D3CBFF',
        200: '#CBBCFF',
        300: '#C5AEFD',
        400: '#C0A0FB',
        500: '#bd93f9',
        600: '#9670D5',
        700: '#7051AB',
        800: '#4C3781',
        900: '#2D2157',
      },
      red: {
        50: '#FFC5D9',
        100: '#FFADC3',
        200: '#FF96AB',
        300: '#FF7F90',
        400: '#FF6A74',
        500: '#ff5555',
        600: '#D53540',
        700: '#AB1D30',
        800: '#810B25',
        900: '#57001B',
      },
      yellow: {
        50: '#FFF7D8',
        100: '#FFF6C8',
        200: '#FFF8B8',
        300: '#FEFAA9',
        400: '#FBFC9A',
        500: '#f1fa8c',
        600: '#D3D569',
        700: '#ABAB4B',
        800: '#818132',
        900: '#57571D',
      },
    },
  },
  fonts: {
    heading: 'IBM Plex Mono',
    body: 'IBM Plex Mono',
  },
};

export default extendTheme(theme);
